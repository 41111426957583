// @flow

import React from 'react';
import type { Nav, NavItem } from '@leasen_nl/listo/src/types/apiRoute';
import { Box } from '@leasen_nl/listo/src/components/grid';
import { StyledFlex, Link } from './atoms';

const today = new Date();
const year = today.getFullYear();

type Props = {
  nav: Nav,
};

const Endline = ({ nav }: Props) => (
  <StyledFlex>
    <Box
      px="0.8rem"
      py="0.2rem"
    >
      <span>
        &copy;
        {year}
      </span>
    </Box>
    {nav.items && nav.items.map((item: NavItem) => (
      <Box
        key={item.id}
        px="0.8rem"
        py="0.2rem"
      >
        <Link href={item.url}>{item.title}</Link>
      </Box>
    ))}
  </StyledFlex>
);

export default Endline;
