// @flow

import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import { getLogo } from '@leasen_nl/listo/src/store/siteSettings';
import type { Theme } from '@types/index';

type WrapperProps = {
  logo: string,
};

const Wrapper = styled<any, Theme, WrapperProps>('div')`
  width: 9.25rem;
  min-height: ${props => (props.theme.mainMenu.heightMobile)};
  ${media.md(css`
    width: 9.25rem;
    margin-right: 0.5rem;
  `)}
  ${media.lg(css`
    min-height: ${props => (props.theme.mainMenu.heightDesktop)};
  `)}
  background-image: url('${props => props.logo}');
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
`;

type StateProps = {|
  logo: ?string,
|};

type Props = StateProps;

const Logo = ({ logo }: Props) => {
  if (!logo) {
    return null;
  }

  return (
    <Wrapper logo={logo} />
  );
};


const mapStateToProps = (state: *): StateProps => ({
  logo: getLogo(state),
});

export default connect<any, any, StateProps, any, any, any>(mapStateToProps)(Logo);
