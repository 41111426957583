// @flow
// A loadableConfirmPage returns the default component mapping,
// but overrides the button with a button with macros.

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Page from '@leasen_nl/listo/src/components/page';
import Loader from '@leasen_nl/listo/src/components/loader';
import { onProductDetailView, addCriteoProductPage } from '@leasen_nl/listo/src/store/gtm';
import { checkVbmrAvailability } from '@leasen_nl/listo/src/store/vbmr/actions';
import { initVbmr } from '@leasen_nl/listo/src/store/configuration/vbmr/actions';

type NativeState = {
  vbmrCheckCompleted: boolean,
};

type DispatchProps = {|
  onPageLoad: Function,
  addCriteoProduct: Function,
  checkVbmr: Function,
|};

type Props = DispatchProps;

class ModelInfoPage extends Component<Props, NativeState> {
  constructor() {
    super();

    this.state = {
      vbmrCheckCompleted: false,
    };
  }

  componentDidMount() {
    const { onPageLoad, addCriteoProduct, checkVbmr } = this.props;
    onPageLoad();
    addCriteoProduct();
    checkVbmr(() => {
      this.setState({
        vbmrCheckCompleted: true,
      });
    });
  }

  render() {
    const { vbmrCheckCompleted } = this.state;
    // Because of Flow compatibility bug in CP: $Diff<OmitDispatch<ElementConfig<Com>>
    // $FlowFixMe
    return vbmrCheckCompleted ? <Page {...this.props} /> : <Loader alwaysLoading />;
  }
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  onPageLoad: () => dispatch(onProductDetailView()),
  addCriteoProduct: () => dispatch(addCriteoProductPage()),
  checkVbmr: (callback: Function) => dispatch(checkVbmrAvailability(() => {
    dispatch(initVbmr());
    callback();
  })),
});

export default connect<any, any, any, DispatchProps, any, any>(
  null,
  mapDispatchToProps,
)(ModelInfoPage);
