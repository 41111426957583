// @flow

export default {
  drawerColumnHeader: 9998,
  pdbCompare: 9998,
  cartColumn: 9998,
  loader: 9998,
  modal: 9999,
  stickyRow: 9997,
  overlay: 9998,
  carblockDiscount: 2,
  carblockNotAvailableOverlay: 3,
  imageGalleryFullscreen: 101,
};
