// @flow

import React from 'react';
import styled from 'styled-components';
import Meta from '@leasen_nl/listo/src/components/meta';
import PdbCompare from '@leasen_nl/listo/src/components/pdbCompare';
import Loader from '@leasen_nl/listo/src/components/loader';
import type { Theme } from '@types/index';
import Favicons from '../favicons';
import Main from '../main';
import Header from '../header';
import Footer from '../footer';

const Justlease = () => (
  <div data-cy="site-content">
    <Meta />
    <Favicons />
    <Loader />
    <Header />
    <WithFont>
      <Main />
    </WithFont>
    <Footer />
    <PdbCompare />
  </div>
);

const WithFont = styled<any, Theme, _>('div')`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight};
`;

export default Justlease;
