// @flow

import React from 'react';
import { connect } from 'react-redux';
import { getUiSetting } from '@leasen_nl/listo/src/store/uiStore';
import { getIsThuiswinkelLogoVisible } from '@leasen_nl/listo/src/store/siteSettings';
import { WrapperLink } from './atoms';

type StateProps = {|
  languageSelectorOpen: boolean,
  showTrustMarkLogo: boolean,
|};

type Props = StateProps;

const TrustMarkPrivateLeaseLogo = ({
  languageSelectorOpen,
  showTrustMarkLogo,
}: Props) => {
  if (!showTrustMarkLogo) {
    return false;
  }

  return (
    <WrapperLink
      href="https://www.keurmerkprivatelease.nl/home"
      target="_blank"
      languageSelectorOpen={languageSelectorOpen}
    >
      <img
        src="/static/images/keurmerklogo.png"
        alt="keurmerk private lease logo"
        style={{ maxWidth: '100%' }}
      />
    </WrapperLink>
  );
};

// FIXME: getIsThuiswinkelLogoVisible SEL-5405
const mapStateToProps = (state: *): StateProps => ({
  languageSelectorOpen: getUiSetting(state, 'languageSelectorOpen'),
  showTrustMarkLogo: getIsThuiswinkelLogoVisible(state),
});

export default connect<any, any, StateProps, any, any, any>(
  mapStateToProps,
)(TrustMarkPrivateLeaseLogo);
