// @flow

import React from 'react';
import PdbInfo from '@leasen_nl/listo/src/components/pdbInfo';
import { type Props } from '@leasen_nl/listo/src/components/pdbInfo/pdbInfo.acf';
import Occasions from './occasions';

export default (props: Props) => (
  <PdbInfo
    {...props}
    renderOccasions={() => <Occasions />}
  />
);
