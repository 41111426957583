// @flow

import { wysiwygWithMacros } from '@leasen_nl/listo/src/components/wysiwyg';
import PdbGallery from '@leasen_nl/listo/src/components/pdbGallery';
import Specifications from '@leasen_nl/listo/src/components/specifications';
import { ACFGallery } from '@leasen_nl/listo/src/components/gallery';
import { ACFImage } from '@leasen_nl/listo/src/components/image';
import { ACFCarBlock } from '@leasen_nl/listo/src/components/carBlock';
import Divider from '@leasen_nl/listo/src/components/divider';
import { ACFSearch, ACFSearchResults } from '@leasen_nl/listo/src/components/faq';
import { ACFFormidable } from '@leasen_nl/listo/src/components/formidable';
import Top10List from '@leasen_nl/listo/src/components/top10List';
import Packages from '@leasen_nl/listo/src/components/packages';
import PdbInfo from './components/pdbInfo';
import List from './components/list';
import IconText from './components/iconText';
import Review from './components/review';
import Button from './components/button';
import PdbCTA from './components/pdbCallToAction';
import ButtonBar from './components/buttonBar';
import FaqCategoryInOverview from './components/faqCategoryInOverview';
import FaqCategoryAccordion from './components/faqCategoryAccordion';
import DirectContact from './components/directContact';
import contract from './wp-contract';

const componentMapping = {
  car_block: ACFCarBlock,
  wysiwyg: wysiwygWithMacros(contract.macros),
  list: List,
  iconic_text: IconText,
  review: Review,
  button: Button,
  pdb_gallery: PdbGallery,
  image: ACFImage,
  pdb_info: PdbInfo,
  gallery: ACFGallery,
  pdb_specs: Specifications,
  pdb_cta: PdbCTA,
  button_bar: ButtonBar,
  faq_category: FaqCategoryInOverview,
  faq_category_accordion: FaqCategoryAccordion,
  faq_search_result: ACFSearchResults,
  contact: DirectContact,
  divider: Divider,
  faq_search_field: ACFSearch,
  form: ACFFormidable,
  top_10: Top10List,
  pdb_packages: Packages,
};

export default componentMapping;
