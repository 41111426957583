// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import { Flex } from '@leasen_nl/listo/src/components/grid';
import type { Theme } from '@types/index';

export const StyledFlex = styled<typeof Flex, Theme, _>(Flex)`
  background-color: ${props => props.theme.footer.backgroundColor};
  border-bottom: 1px solid ${props => props.theme.footer.horizontalLine};
  flex-direction: column;
  ${media.lg(css`
    flex-direction: row-reverse;
  `)}
`;

export const StyledFooter = styled<any, Theme>('footer')`
  background-color: ${props => props.theme.footer.backgroundColor};
  ${media.md(css`
    padding-top: 1rem;
    padding-bottom: 2rem;
  `)}
  ${media.lg(css`
    padding-top: 2rem;
  `)}
`;

export const DisclaimerText = styled<any, Theme>('section')`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1rem;

  p {
    font-size: 0.7rem;
    font-style: italic;
    max-width: 38rem;
    text-align: center;
  }
`;

export default null;
