// @flow

type Arguments = {
  message: string,
  status: number,
};

class FetchError extends Error {
  meta: Object;

  status: number;

  constructor({ message = '', status, ...rest }: Arguments) {
    super(message);

    this.name = 'FetchError';
    this.status = status;
    this.meta = rest;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }
  }
}

export default FetchError;
