// @flow

/* This is client only! */
import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import Loadable from 'react-loadable';
import { makeStore } from './store';
import Project from './project';
import loadPolyfills from './polyfills';

declare var __INITIAL_STATE__: Object;
declare var module: Object;

const history = createBrowserHistory();
const initialState = __INITIAL_STATE__;

const store = makeStore(history, initialState);

const Router = ({ children }) => <ConnectedRouter history={history}>{children}</ConnectedRouter>;

const rootEl = document.querySelector('#root');

if (!rootEl) {
  throw new Error('Cannot find Div element in document');
}

const render = () => {
  ReactDOM.render(
    <Project
      store={store}
      Router={Router}
    />,
    rootEl,
  );
};

loadPolyfills()
  .then(Loadable.preloadReady)
  .then(() => {
    render();
  });
