// @flow

export default [
  {
    name: 'Open Sans',
    locals: ['Open Sans Light', 'OpenSans-Light'],
    unicodeRange: ['latin', 'latinExt'],
    urls: {
      woff2: 'https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTRampu5_7CjHW5spxoeN3Vs.woff2',
      woff: 'https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUuhv.woff',
    },
    weight: 300,
  },
  {
    name: 'Open Sans',
    locals: ['Open Sans Regular', 'OpenSans-Regular'],
    unicodeRange: ['latin', 'latinExt'],
    urls: {
      woff2: 'https://fonts.gstatic.com/s/opensans/v15/cJZKeOuBrn4kERxqtaUH3ZBw1xU1rKptJj_0jans920.woff2',
      woff: 'https://fonts.gstatic.com/s/opensans/v15/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff',
    },
    weight: 400,
  },
  {
    name: 'Open Sans',
    locals: ['Open Sans SemiBold', 'OpenSans-SemiBold'],
    unicodeRange: ['latin', 'latinExt'],
    urls: {
      woff2: 'https://fonts.gstatic.com/s/opensans/v15/MTP_ySUJH_bn48VBG8sNShampu5_7CjHW5spxoeN3Vs.woff2',
      woff: 'https://fonts.gstatic.com/s/opensans/v15/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff',
    },
    weight: 600,
  },
  {
    name: 'Open Sans',
    locals: ['Open Sans Bold', 'OpenSans-Bold'],
    unicodeRange: ['latin', 'latinExt'],
    urls: {
      woff2: 'https://fonts.gstatic.com/s/opensans/v15/k3k702ZOKiLJc3WVjuplzBampu5_7CjHW5spxoeN3Vs.woff2',
      woff: 'https://fonts.gstatic.com/s/opensans/v15/k3k702ZOKiLJc3WVjuplzHhCUOGz7vYGh680lGh-uXM.woff',
    },
    weight: 700,
  },
];
