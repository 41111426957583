// @flow

import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { Flex, Container } from '@leasen_nl/listo/src/components/grid';
import { breakpointsToArray } from '@leasen_nl/listo/src/helpers';
import OrderForm from '@leasen_nl/listo/src/components/orderForm';
import media from '@leasen_nl/listo/src/helpers/media';
import { Redirect, withRouter } from 'react-router-dom';
import type { Theme } from '@types/index';
import { getCart } from '@leasen_nl/listo/src/store/configuration/cart/selectors';
import { getActivePdbId, getConfigLink, getConfirmationLink } from '@leasen_nl/listo/src/store/pdb';
import { ACFFormidable } from '@leasen_nl/listo/src/components/formidable';
import type { ACFFormData } from '@leasen_nl/listo/src/components/formidable/types';
import { push } from 'connected-react-router';
import { Heading, Cart, List } from './atoms';
import type { ListProps } from './atoms/list/types';

const Wrapper = styled<typeof Flex, Theme>(Flex)`
  ${media.md(css`
    padding: 2rem 1rem 2rem 0;
  `)}
`;

type OwnProps = {|
  acf: {
    list?: ListProps,
    heading: string,
    form_data?: ?ACFFormData,
    form_data_secondary?: ?ACFFormData,
  },
  history: any,
|};

type DispatchProps = {|
  redirectToConfig: Function,
  redirectToConfirmation: Function,
|};

type StateProps = {|
  configLink: string,
  confirmLink: string,
  hasCart: boolean,
|};

type Props = OwnProps & DispatchProps & StateProps;

export const formName = 'order';

const OrderPage = ({
  acf: {
    heading,
    list,
    form_data,
    form_data_secondary,
  },
  history: {
    location: {
      pathname,
    },
  },
  hasCart,
  configLink,
  redirectToConfirmation,
  confirmLink,
}: Props) => {
  if (!hasCart) {
    return <Redirect to={configLink} />;
  }
  let formData = form_data;
  // TODO: Use React Router v4 the fetch the search query param, see:
  // https://justlease.atlassian.net/browse/WPW-558
  if (pathname.split('offer=')[1] && form_data_secondary) {
    formData = form_data_secondary;
  }
  return (
    <Container>
      <Flex flexDirection="row" wrap px={breakpointsToArray({ default: '1.25rem', sm: 0 })}>
        <Wrapper
          order={1}
          width={breakpointsToArray({ default: 1, lg: 8 / 12 })}
          flexDirection="column"
        >
          <Heading>{heading}</Heading>
          {!formData && <OrderForm form={formName} /> }
          {formData
            && (
            <ACFFormidable
              form_data={formData}
              onSuccess={() => redirectToConfirmation(confirmLink)}
            />
            )}
        </Wrapper>
        <Flex
          order={breakpointsToArray({ default: 0, lg: 2 })}
          width={breakpointsToArray({ default: 1, lg: 4 / 12 })}
          p="2rem 1rem"
          flexDirection="column"
        >
          <Cart
            dimensions={{ default: 1 }}
            displayActions={false}
            boxed
          />
          {list && (<List {...list} />)}
        </Flex>
      </Flex>
    </Container>
  );
};

const mapStateToProps = (state: *): StateProps => ({
  hasCart: Boolean(getCart(state)),
  configLink: getConfigLink(state, getActivePdbId(state)) || '/',
  confirmLink: getConfirmationLink(state, getActivePdbId(state)) || '/',
});

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  redirectToConfig: (configLink: string) => {
    dispatch(push(configLink));
  },
  redirectToConfirmation: (confirmLink: string) => {
    dispatch(push(confirmLink));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderPage));
