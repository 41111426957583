// @flow

import React from 'react';
import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import List from '@leasen_nl/listo/src/components/list/list.acf';
import type { Theme } from '@types/index';
import type { ListProps as Props } from './types';

const DesktopList = styled<any, Theme>('div')`
  display: none;

  ${media.lg(css`
    display: block;
  `)}
`;

const TabletMobileList = styled<any, Theme>('div')`
  display: block;

  ${media.lg(css`
    display: none;
  `)}
`;

// @todo: yes, this is a bad solution and should be optimized
export default (props: Props) => [
  <DesktopList key="desktop-list"><List {...props} /></DesktopList>,
  <TabletMobileList key="tablet-mobile-list">
    <List {...props} minimalistic />
  </TabletMobileList>,
];
