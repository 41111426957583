const icons = [
  {
    title: 'Kleine auto (zijkant)',
    name: 'car_small',
  },
  {
    title: 'Medium auto (zijkant)',
    name: 'car_medium',
  },
  {
    title: 'Grote auto (zijkant)',
    name: 'car_large',
  },
  {
    title: 'Auto (voorkant)',
    name: 'car',
    aliases: ['icon-aanbod'],
  },
  {
    title: 'Voeg foto toe (camera)',
    name: 'add_photo',
  },
  {
    title: 'Levertijd snel (kalender met klok)',
    name: 'calendar_clock',
  },
  {
    title: 'Leverdatum toevoegen (kalender met plusje)',
    name: 'calendar_plus',
  },
  {
    title: 'Leverdatum onbekend (kalender met vraagteken)',
    name: 'calendar_question_mark',
  },
  {
    title: 'Schadevrij (auto met vinkje)',
    name: 'car_check',
  },
  {
    title: 'Contract stoppen (auto met kruis)',
    name: 'car_cross',
  },
  {
    title: 'Rijdende auto (auto zijkant)',
    name: 'car_driving',
  },
  {
    title: 'Contract afkopen (auto met euro-teken)',
    name: 'car_money',
  },
  {
    title: 'Nieuwe auto (auto met sterretjes)',
    name: 'car_new',
  },
  {
    title: 'Occasion (auto met korting)',
    name: 'car_occasion',
  },
  {
    title: 'Tweede auto',
    name: 'car_second',
  },
  {
    title: 'Auto inruilen (auto met pijltje)',
    name: 'car_swap',
  },
  {
    title: 'Tijdelijke auto (auto met klokje)',
    name: 'car_temporary',
  },
  {
    title: 'Auto upgrade',
    name: 'car_upgrade',
  },
  {
    title: 'Vakantie (beladen auto)',
    name: 'car_vacation',
  },
  {
    title: 'Chat wolkje',
    name: 'chat',
  },
  {
    title: 'Vinkje in cirkel (leeg)',
    name: 'circle_check_empty',
  },
  {
    title: 'Vinkje in circel (uitgevuld)',
    name: 'circle_check_fill',
  },
  {
    title: 'Uitroepteken in circel',
    name: 'circle_exclamation_mark',
  },
  {
    title: 'Vraagteken in circel',
    name: 'circle_question_mark',
  },
  {
    title: 'Stad',
    name: 'city',
  },
  {
    title: 'Bundelgeschiedenis (klok history)',
    name: 'clock_history',
  },
  {
    title: 'Klok',
    name: 'clock',
  },
  {
    title: 'Bedrijf',
    name: 'company',
  },
  {
    title: 'Tell-a-friend (gesprekswolkjes)',
    name: 'conversation',
  },
  {
    title: 'Dashboard',
    name: 'dashboard',
  },
  {
    title: 'Korting labeltje',
    name: 'discount',
  },
  {
    title: 'Download',
    name: 'download',
  },
  {
    title: 'FAQ (wolkje met vraagteken)',
    name: 'faq',
  },
  {
    title: 'Flexibiliteit',
    name: 'flexibility',
  },
  {
    title: 'Cadeau',
    name: 'gift',
  },
  {
    title: 'Boodschappen',
    name: 'groceries',
  },
  {
    title: 'Factuur',
    name: 'invoice',
  },
  {
    title: 'Kilometers (metertje)',
    name: 'km_meter',
  },
  {
    title: 'Bundelstatus (metertje historie)',
    name: 'km_status',
  },
  {
    title: 'Hamburgermenu met puntjes',
    name: 'menu_config',
  },
  {
    title: 'Rinkelende mobiel',
    name: 'mobile_ringing',
  },
  {
    title: 'Play icoon',
    name: 'play_video',
  },
  {
    title: 'Beloning',
    name: 'reward',
  },
  {
    title: 'Spaarpot',
    name: 'save_money',
  },
  {
    title: 'Opslaan',
    name: 'save',
  },
  {
    title: 'Webshop',
    name: 'webshop',
  },
  {
    title: 'Whatsapp',
    name: 'whatsapp',
  },
  {
    title: 'Vinkje',
    name: 'check',
  },
  {
    title: 'Chevron',
    name: 'chevron',
  },
  {
    title: 'Contract',
    name: 'contract',
    aliases: ['icon-contract'],
  },
  {
    title: 'Kruis',
    name: 'cross',
  },
  {
    title: 'Klantenservice',
    name: 'customer_service',
    aliases: ['customerservice'],
  },
  {
    title: 'Schade',
    name: 'damage',
    aliases: ['icon-schade'],
  },
  {
    title: 'Snelle levertijd',
    name: 'delivery_time',
    aliases: ['icon-levertijd'],
  },
  {
    title: 'Documenten',
    name: 'documents',
    aliases: ['icon-voorwaarden'],
  },
  {
    title: 'Envelop',
    name: 'envelope',
  },
  {
    title: 'Facebook',
    name: 'facebook',
  },
  {
    title: 'Hamburger menu',
    name: 'hamburger',
  },
  {
    title: 'Sleutel',
    name: 'key',
    aliases: ['icon-sleutel'],
  },
  {
    title: 'Gereedschap (onderhoud)',
    name: 'maintenance',
    aliases: ['icon-onderhoud'],
  },
  {
    title: 'Locatie marker',
    name: 'marker',
  },
  {
    title: 'Telefoon',
    name: 'telephone',
  },
  {
    title: 'Thuiswinkel waarborg logo',
    name: 'thuiswinkel_waarborg',
  },
  {
    title: 'Twitter',
    name: 'twitter',
  },
  {
    title: 'Google Plus',
    name: 'google_plus',
  },
  {
    title: 'LinkedIn',
    name: 'linkedin',
  },
  {
    title: 'Gebruiker',
    name: 'user',
  },
  {
    title: 'Portemonnee',
    name: 'wallet',
    aliases: ['icon-financieel'],
  },
];

export default icons;
