// @flow

import styled from 'styled-components';
import Wysiwyg from '@leasen_nl/listo/src/components/wysiwyg';
import type { Theme } from '@types/index';

const StyledWysiwyg = styled<typeof Wysiwyg, Theme, _>(Wysiwyg)`
  color: ${props => props.theme.footer.subTextColor};
  font-size: 1rem;
`;

export default StyledWysiwyg;
