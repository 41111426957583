// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import type { Theme } from '@types/index';

const Wrapper = styled<any, Theme>('section')`
  display: none;
  ${media.md(css`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    align-items: center;
  `)}
  ${media.lg(css`
    justify-content: center;
    margin-top: 0.1rem;
  `)}
`;

export default Wrapper;
