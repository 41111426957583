// @flow

// Parses a slug to contain the slug and additional query params
const parseSlug = (slug: string): Object => {
  const queryString = {};

  if (slug.includes('?')) {
    queryString.slug = slug.substring(0, slug.indexOf('?'));
    slug.replace(
      /([^?=&]+)(=([^&]*))?/g,
      (fullMatch, key, partialMatch, value): string => {
        if (fullMatch.includes('=') && value) {
          queryString[key] = decodeURIComponent(value);
        }

        return '';
      },
    );
  } else {
    queryString.slug = slug;
  }
  return queryString;
};

export default parseSlug;
