// @flow
import { css } from 'styled-components';
import terbergbusiness, {
  fonts,
  FONT_DEFAULT,
  FONT_WEIGHT_DEFAULT,
  BRAND_PRIMARY,
  WHITE,
  BRAND_SECONDARY,
  BRAND_SECONDARY_DARK,
  TEXT_SECONDARY,
  MAIN_MENU_SCHEME,
} from '@leasen_nl/listo/src/themes/terbergbusiness';
import baseTheme from './base.theme';
import type { Theme } from '../types';

export const globalCss = css`
  ${fonts}
`;

const theme: Theme = {
  ...terbergbusiness,
  ...baseTheme,
  font: {
    family: FONT_DEFAULT,
    weight: FONT_WEIGHT_DEFAULT,
  },
  footer: {
    ...baseTheme.footer,
    backgroundColor: WHITE,
    textColor: BRAND_SECONDARY,
    subTextColor: TEXT_SECONDARY,
    icon: {
      borderColor: BRAND_PRIMARY,
      fillColor: BRAND_PRIMARY,
    },
    button: {
      ...baseTheme.footer.button,
      backgroundColor: BRAND_SECONDARY,
      borderColor: BRAND_SECONDARY,
      hoverColor: BRAND_SECONDARY_DARK,
    },
  },
  mainMenu: {
    ...baseTheme.mainMenu,
    scheme: MAIN_MENU_SCHEME,
  },
  subMenu: {
    ...baseTheme.subMenu,
    backgroundColor: BRAND_PRIMARY,
  },
  orderPage: {
    header: {
      color: BRAND_PRIMARY,
    },
  },
};
export default theme;
