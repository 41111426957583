// @flow

import { css } from 'styled-components';
import globalListoCss from '@leasen_nl/listo/src/themes/global';
import { themeBreakpoints } from '@leasen_nl/listo/src/helpers/breakpoints';
import generateFontFace from '@leasen_nl/listo/src/helpers/generateFontFace';
import {
  MAIN_MENU_SCHEME,
  SUB_MENU_SCHEME,
  BRAND_PRIMARY,
  BRAND_PRIMARY_DARK,
  BRAND_SECONDARY,
  BRAND_TERTIARY,
  BRAND_SECONDARY_DARK,
  GREEN,
  RED,
  WHITE,
  BACKGROUND_DEFAULT,
  BACKGROUND_LIGHT,
  TEXT_SECONDARY,
  TEXT_TERTIARY,
  TEXT_LIGHT,
  TEXT_DEFAULT,
  HEADING_DEFAULT,
  BORDER_COLOR,
  BORDER_RADIUS_DEFAULT,
  BORDER_RADIUS_SMALL,
  FONT_DEFAULT,
  FONT_WEIGHT_DEFAULT,
  SILVER,
  SILVER_LIGHT,
  FONT_SIZE_H1,
  FONT_SIZE_H2,
  FONT_SIZE_H3,
  FONT_SIZE_H4,
  FONT_SIZE_H5,
  FONT_SIZE_H6,
  FONT_SIZE_SMALL,
  FONT_SIZE_TINY,
  FONT_HEADING,
  GRAY_DARK,
  LIGHT_GREEN,
  LIGHT_RED,
} from '@leasen_nl/listo/src/themes/justlease';
import fonts from './base.fonts';
import type { BaseTheme } from '../types';
import { BOLD, SEMIBOLD } from '../helpers/fontWeight';


const ERROR = RED;
const BACKGROUND_ERROR = LIGHT_RED;
const SUCCESS = GREEN;
const BACKGROUND_SUCCESS = LIGHT_GREEN;

// tinyCss is the css which is used in the WordPress backend
export const tinyCss = css`
  ${globalListoCss}
  ${generateFontFace(fonts)}

  body {
    font-family: ${FONT_DEFAULT};
    font-weight: ${FONT_WEIGHT_DEFAULT};
    margin: 0;
  }
`;

export const globalCss = css`
  ${generateFontFace(fonts)}

  body {
    font-family: ${FONT_DEFAULT};
    font-weight: ${FONT_WEIGHT_DEFAULT};
  }
`;

const theme: BaseTheme = {
  breakpoints: themeBreakpoints,
  hasDiagonal: false,
  accordion: {
    backgroundColor: BACKGROUND_LIGHT,
    backgroundColorHover: BORDER_COLOR,
    borderColor: BACKGROUND_LIGHT,
    borderRadius: BORDER_RADIUS_DEFAULT,
    color: TEXT_DEFAULT,
  },
  backgroundColor: {
    default: BACKGROUND_DEFAULT,
    primary: BRAND_PRIMARY,
    secondary: BRAND_SECONDARY,
    tertiary: BRAND_TERTIARY,
    light: BACKGROUND_LIGHT,
  },
  dataListCard: {
    borderRadius: BORDER_RADIUS_DEFAULT,
    keyColor: TEXT_SECONDARY,
    valueColor: TEXT_DEFAULT,
    rowBorderColor: BORDER_COLOR,
    backgroundColor: BACKGROUND_LIGHT,
    titleColor: HEADING_DEFAULT,
  },
  footer: {
    backgroundColor: BACKGROUND_LIGHT,
    textColor: HEADING_DEFAULT,
    subTextColor: TEXT_SECONDARY,
    horizontalLine: BORDER_COLOR,
    icon: {
      borderColor: BRAND_SECONDARY,
      fillColor: BRAND_SECONDARY,
    },
    newsletter: {
      confirmationTitle: HEADING_DEFAULT,
      confirmationText: TEXT_SECONDARY,
      iconSuccess: GREEN,
      iconFail: RED,
    },
    inputField: {
      backgroundColor: BACKGROUND_DEFAULT,
      color: TEXT_SECONDARY,
      borderColor: BORDER_COLOR,
      borderColorError: RED,
      errorMessage: RED,
      placeholderColor: TEXT_TERTIARY,
      height: '2.5rem',
    },
    button: {
      backgroundColor: BRAND_SECONDARY,
      color: TEXT_LIGHT,
      borderColor: BRAND_SECONDARY,
      hoverColor: BRAND_SECONDARY_DARK,
    },
  },

  formidable: {
    checkbox: {
      backgroundColor: {
        default: WHITE,
        selected: BRAND_SECONDARY,
        disabled: BORDER_COLOR,
      },
      borderColor: {
        default: BORDER_COLOR,
        selected: BRAND_SECONDARY,
        disabled: BORDER_COLOR,
        error: RED,
      },
      checkColor: WHITE,
    },
    description: {
      color: TEXT_SECONDARY,
      fontSize: FONT_SIZE_SMALL,
      fontWeight: '400',
    },
    error: {
      borderColor: ERROR,
      backgroundColor: ERROR,
      color: TEXT_LIGHT,
      borderRadiusBottom: `0 0 ${BORDER_RADIUS_SMALL} ${BORDER_RADIUS_SMALL}`,
      borderRadius: BORDER_RADIUS_SMALL,
    },
    input: { // Select and textarea
      backgroundColor: {
        default: WHITE,
        disabled: SILVER_LIGHT,
      },
      borderColor: {
        default: BORDER_COLOR,
        error: ERROR,
        disabled: BORDER_COLOR,
      },
      borderRadius: {
        default: BORDER_RADIUS_SMALL,
        error: `${BORDER_RADIUS_SMALL} ${BORDER_RADIUS_SMALL} 0 0`,
      },
      focusColor: BRAND_TERTIARY,
      placeholderColor: TEXT_TERTIARY,
    },
    radio: {
      backgroundColor: WHITE,
      borderColor: {
        default: BORDER_COLOR,
        selected: BRAND_SECONDARY,
        disabled: BORDER_COLOR,
        error: RED,
      },
      checkedColor: {
        default: BRAND_SECONDARY,
        selected: BRAND_SECONDARY,
        disabled: BORDER_COLOR,
      },
    },
    submitBar: {
      backgroundColor: {
        error: BACKGROUND_ERROR,
        success: BACKGROUND_SUCCESS,
      },
      color: {
        error: ERROR,
        success: SUCCESS,
      },
      borderRadius: BORDER_RADIUS_SMALL,
    },
  },

  topMenu: {
    backgroundColorActive: BACKGROUND_DEFAULT,
    backgroundColor: BACKGROUND_LIGHT,
    textColor: TEXT_SECONDARY,
    borderColor: BORDER_COLOR,
    openColor: GREEN,
    closedColor: RED,
    height: '3rem',
  },
  mainMenu: {
    backgroundColor: BACKGROUND_DEFAULT,
    scheme: MAIN_MENU_SCHEME,
    heightMobile: '4rem',
    heightDesktop: '6.05rem',
  },
  subMenu: {
    scheme: SUB_MENU_SCHEME,
    backgroundColor: BRAND_TERTIARY,
    contrastColor: BRAND_PRIMARY,
  },
  orderPage: {
    header: {
      color: BRAND_PRIMARY,
    },
  },
  faqCategoryPage: {
    submenuBorderColor: SILVER,
  },
  pdbPrice: {
    list: {
      border: `1px solid ${BORDER_COLOR}`,
    },
    borderColor: BORDER_COLOR,
    mileageAndDuration: {
      color: TEXT_SECONDARY,
      fontSize: {
        default: FONT_SIZE_TINY,
        lg: FONT_SIZE_SMALL,
      },
    },
    fromPrice: {
      color: BRAND_SECONDARY,
      fontSize: FONT_SIZE_SMALL,
    },
    fromPriceText: {
      color: HEADING_DEFAULT,
      fontSize: FONT_SIZE_SMALL,
    },
    price: {
      color: BRAND_PRIMARY,
      fontSize: FONT_SIZE_H1,
      fontFamily: FONT_HEADING,
    },
  },
  span: {
    color: {
      default: BRAND_PRIMARY,
      primary: BRAND_PRIMARY,
      sub: BRAND_PRIMARY,
      none: 'currentColor',
    },
  },
  cardPadding: {
    default: '1.5rem',
    md: '2rem',
  },
  tabBar: {
    active: {
      backgroundColor: BRAND_PRIMARY,
      backgroundColorHover: BRAND_PRIMARY_DARK,
      borderColor: BRAND_PRIMARY,
      color: WHITE,
    },
    default: {
      backgroundColor: WHITE,
      backgroundColorHover: SILVER_LIGHT,
      borderColor: BORDER_COLOR,
      color: TEXT_DEFAULT,
    },
    borderRadius: BORDER_RADIUS_DEFAULT,
  },
  heading: {
    h1: {
      color: {
        default: BRAND_PRIMARY,
        primary: BRAND_PRIMARY,
        sub: GRAY_DARK,
        none: 'currentColor',
      },
      fontWeight: {
        primary: BOLD,
        default: BOLD,
        sub: SEMIBOLD,
      },
      fontSize: FONT_SIZE_H1,
      lineHeight: '1.375em',
      margin: '0 0 0.25rem',
    },
    h2: {
      color: {
        default: BRAND_PRIMARY,
        primary: BRAND_PRIMARY,
        sub: GRAY_DARK,
        none: 'currentColor',
      },
      fontWeight: {
        primary: BOLD,
        default: BOLD,
        sub: SEMIBOLD,
      },
      fontSize: FONT_SIZE_H2,
      lineHeight: '1.25',
      margin: '0 0 0.25rem',
    },
    h3: {
      color: {
        default: BRAND_PRIMARY,
        primary: BRAND_PRIMARY,
        sub: GRAY_DARK,
        none: 'currentColor',
      },
      fontWeight: {
        primary: BOLD,
        default: BOLD,
        sub: SEMIBOLD,
      },
      fontSize: FONT_SIZE_H3,
      lineHeight: '1.5',
      margin: '0 0 0.25rem',
    },
    h4: {
      color: {
        default: BRAND_PRIMARY,
        primary: BRAND_PRIMARY,
        sub: GRAY_DARK,
        none: 'currentColor',
      },
      fontWeight: {
        primary: BOLD,
        default: BOLD,
        sub: SEMIBOLD,
      },
      fontSize: FONT_SIZE_H4,
      lineHeight: '1.25',
      margin: '0 0 0.25rem',
    },
    h5: {
      color: {
        default: BRAND_PRIMARY,
        primary: BRAND_PRIMARY,
        sub: GRAY_DARK,
        none: 'currentColor',
      },
      fontWeight: {
        primary: BOLD,
        default: BOLD,
        sub: SEMIBOLD,
      },
      fontSize: FONT_SIZE_H5,
      lineHeight: '1',
      margin: '0 0 0.25rem',
    },
    h6: {
      color: {
        default: BRAND_PRIMARY,
        primary: BRAND_PRIMARY,
        sub: GRAY_DARK,
        none: 'currentColor',
      },
      fontWeight: {
        primary: BOLD,
        default: BOLD,
        sub: SEMIBOLD,
      },
      fontSize: FONT_SIZE_H6,
      lineHeight: '1',
      margin: '0 0 0.25rem',
    },
  },
};

export default theme;
