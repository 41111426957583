// @flow

import React from 'react';
import styled from 'styled-components';
import ListoMain from '@leasen_nl/listo/src/components/main';
import type { Theme } from '@types/index';
import templateMapping from '../../templateMapping';
import posttypeMapping from '../../posttypeMapping';

type Props = {
  className: string,
};

const Main = ({
  className,
  ...props
}: Props) => (
  <ListoMain
    {...props}
    className={className}
    posttypeMapping={posttypeMapping}
    templateMapping={templateMapping}
  />
);

export default styled<typeof Main, Theme, _>(Main)`
  overflow: hidden;
  transition: margin-top 0.2s linear;
`;
