// @flow

import React from 'react';
import { connect } from 'react-redux';
import ListoLanguageSelector from '@leasen_nl/listo/src/components/languageSelector';
import { getActiveLanguage } from '@leasen_nl/listo/src/store/activeLanguage';
import withHandleComponentToggle from '@leasen_nl/listo/src/components/withHandleComponentToggle';
import { isMultiLanguage } from '@leasen_nl/listo/src/helpers';
import { compose } from 'redux';
import {
  Wrapper,
  ActiveLanguage,
  Dropdown,
} from './atoms';

type StateProps = {|
  activeLanguage: string,
|};

type OwnProps = {|
  handleOpen: Function,
  handleClose: Function,
  open: boolean,
|};

type Props = {|
  ...StateProps,
  ...OwnProps,
|};

const LanguageSelector = ({
  activeLanguage,
  handleOpen,
  handleClose,
  open,
}: Props) => {
  if (!isMultiLanguage()) {
    return false;
  }

  return (
    <Wrapper
      onMouseLeave={handleClose}
      languageSelectorOpen={open}
      onClick={handleOpen}
    >
      <ActiveLanguage onMouseEnter={handleOpen}>
        {activeLanguage}
      </ActiveLanguage>
      <Dropdown languageSelectorOpen={open}>
        <ListoLanguageSelector />
      </Dropdown>
    </Wrapper>
  );
};

const mapStateToProps = (state: *): StateProps => ({
  activeLanguage: getActiveLanguage(state),
});

export default compose(
  connect<any, OwnProps, StateProps, any, any, any>(mapStateToProps),
  withHandleComponentToggle('language-selector'),
)(LanguageSelector);
