// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import type { Theme } from '@types/index';

const Header = styled<any, Theme>('div')`
  color: ${props => props.theme.footer.textColor};
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: bold;
  padding-top: 0.875rem;

  ${media.md(css`
    padding-top: 0;
  `)}
`;

export default Header;
