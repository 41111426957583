// @flow

import type { ComponentType } from 'react';
import icons from './icons';

type Asset = {|
  title: string,
  name: string,
  icon: string | ComponentType<*>,
  aliases?: Array<string>,
|};

export type SvgMapping = {
  icons: Array<Asset>,
};

const svgMapping: SvgMapping = {
  icons,
};

export default svgMapping;
