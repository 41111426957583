// @flow

const resolveOrder = (order: number) => {
  switch (order) {
    case 2:
      return 3;
    case 3:
      return 2;
    default:
      return order;
  }
};

export default resolveOrder;
