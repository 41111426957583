// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import type { Theme } from '@types/index';

const Bullet = styled<any, Theme>('div')`
  display: none;
  background-color: ${props => props.theme.topMenu.openColor};
  border-radius: 50%;
  height: 0.5rem;
  width: 0.5rem;
  ${media.md(css`
    display: flex;
    align-self: center;
    margin-right: 0.625rem;
  `)}
`;

export default Bullet;
