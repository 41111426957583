// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import type { Theme } from '@types/index';

const Text = styled<any, Theme>('div')`
  display: none;

  ${media.md(css`
    display: flex;
    line-height: 1em;
  `)}
`;

export default Text;
