// @flow

import styled from 'styled-components';
import Wysiwyg from '@leasen_nl/listo/src/components/wysiwyg';
import type { Theme } from '@types/index';

const StyledWysiwyg = styled<any, Theme>(Wysiwyg)`
  color: ${props => props.theme.footer.subTextColor};
  font-size: 1rem;
  margin: 0;

  p {
    margin-top: 0;
  }

  a {
    color: ${props => props.theme.footer.textColor};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default StyledWysiwyg;
