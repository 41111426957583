// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import type { Theme } from '@types/index';

const Wrapper = styled<any, Theme>('div')`
  display: none;
  color: ${props => props.theme.topMenu.openColor};
  border-right: 1px solid ${props => props.theme.topMenu.borderColor};

  ${media.md(css`
    display: flex;
    flex-grow: 1;
    flex-basis: auto;
    justify-content: center;
    align-items: center;
  `)}

  ${media.lg(css`
    flex-grow: 0;
    padding: 0.5rem 1.5rem;
  `)}
`;

export default Wrapper;
