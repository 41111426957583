// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import type { Theme } from '@types/index';
import { grid, gridTemplateRows, gridTemplateColumns } from '@helpers/index';
import { resolveOrder } from '../../../../helpers';

type Props = {
  sortOrder: number,
};

const Wrapper = styled<any, Theme, Props>('section')`
  ${grid()}
  ${gridTemplateRows('50% 50%')}
  ${gridTemplateColumns('15% 85%')}
  
  margin-top: 1.325rem;
  
  ${media.md(css`
    order: ${props => resolveOrder(props.sortOrder)};
  `)}
`;

export default Wrapper;
