// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import type { Theme } from '@types/index';

type Props = {
  languageSelectorOpen: boolean,
};

const Wrapper = styled<any, Theme, Props>('div')`
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.topMenu.textColor};
  border-style: solid;
  border-color: ${props => (props.languageSelectorOpen ? 'transparent' : `${props.theme.topMenu.borderColor}`)};
  border-width: 0 0 0 1px;
  box-shadow: ${props => (props.languageSelectorOpen ? '0 2px 4px 0 rgba(0, 0, 0, 0.5)' : '0')};
  background-color: ${props => (props.languageSelectorOpen ? `${props.theme.topMenu.backgroundColorActive}` : `${props.theme.topMenu.backgroundColor}`)};

  &::after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    background-color: white;
    display: ${props => (props.languageSelectorOpen ? 'block' : 'none')};
  }

  ${media.md(css`
    flex-basis: auto;
  `)}
  ${media.lg(css`
    flex-grow: 0;
  `)}
`;

export default Wrapper;
