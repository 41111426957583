// @flow

import React from 'react';
import { Header, StyledWysiwyg, Wrapper } from './atoms';
import ContactOptions from './contactOptions';

type OwnProps = {|
  footerTitle: string,
  footerSubtitle: string,
|};

type Props = OwnProps;

const Contact = ({ footerTitle, footerSubtitle }: Props) => (
  <Wrapper>
    <Header>{footerTitle}</Header>
    <StyledWysiwyg
      content={footerSubtitle}
      template="default"
    />
    <ContactOptions />
  </Wrapper>
);

export default Contact;
