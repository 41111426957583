// @flow

import {
  justleaseTheme,
  justleasebeTheme,
  mediamarktTheme,
  telegraafTheme,
  plusTheme,
  carrefourTheme,
  terbergbusinessTheme,
  privateleasecomTheme,
} from '../theme';

const resolveTheme = () => {
  const theme = {
    justlease: justleaseTheme,
    justleasebe: justleasebeTheme,
    mediamarkt: mediamarktTheme,
    telegraaf: telegraafTheme,
    plus: plusTheme,
    carrefour: carrefourTheme,
    terbergbusiness: terbergbusinessTheme,
    privateleasecom: privateleasecomTheme,
  }[process.env.LISTO_THEME || 'justlease'];

  if (!theme) {
    return justleaseTheme;
  }

  return theme;
};

export default resolveTheme;
