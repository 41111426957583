// @flow

import React from 'react';
import { ACFCategoryInOverview } from '@leasen_nl/listo/src/components/faq';
import type { ACFProps } from '@leasen_nl/listo/src/components/faq/categoryInOverview';

const FAQCategoryInOverview = (props: ACFProps) => (
  <ACFCategoryInOverview {...props} />
);

export default FAQCategoryInOverview;
