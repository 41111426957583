// @flow

import React from 'react';
import { ACFDirectContact } from '@leasen_nl/listo/src/components/directContact';
import type { ACFProps } from '@leasen_nl/listo/src/components/directContact';

const DirectContact = (props: ACFProps) => (
  <ACFDirectContact {...props} />
);

export default DirectContact;
