// @flow

import React from 'react';
import styled from 'styled-components';
import type {
  FooterSettings,
} from '@leasen_nl/listo/src/types/settings';
import type { Nav as NavType } from '@leasen_nl/listo/src/store/menus/types';
import { getFooterMenu, getFooterBottomMenu } from '@leasen_nl/listo/src/store/menus/selectors';
import getFooterSettings from '@leasen_nl/listo/src/store/footerSettings/selectors';
import { Container } from '@leasen_nl/listo/src/components/grid';
import type { Theme } from '@types/index';
import { connect } from 'react-redux';
import { StyledFooter, StyledFlex, DisclaimerText } from './footer.styled';
import {
  Contact, Nav, Logos, Endline,
} from './atoms';
import SubscribeToNewsletter from '../newsletter';

const Wrapper = styled<any, Theme, _>('div')`
  padding: 0 1rem;
`;

type StateProps = {|
  settings: FooterSettings,
  footer_menu: NavType,
  footer_bottom_menu: NavType,
|};

type Props = StateProps;

const Footer = ({
  settings: {
    logos,
    footer_title,
    footer_subtitle,
    newsletter,
    footer_disclaimer_text,
  },
  footer_menu,
  footer_bottom_menu,
}: Props) => (
  <StyledFooter data-cy="site-footer">
    <Container>
      <Wrapper>
        <StyledFlex>
          <SubscribeToNewsletter newsletter={newsletter} />
          <Contact
            footerTitle={footer_title}
            footerSubtitle={footer_subtitle}
          />
        </StyledFlex>
        <Nav nav={footer_menu} />
        <Logos logos={logos} />
        <Endline nav={footer_bottom_menu} />
        <DisclaimerText>
          <p>
            {footer_disclaimer_text}
          </p>
        </DisclaimerText>
      </Wrapper>
    </Container>
  </StyledFooter>
);

const mapStateToProps = (state: *): StateProps => ({
  settings: getFooterSettings(state),
  footer_menu: getFooterMenu(state),
  footer_bottom_menu: getFooterBottomMenu(state),
});

export default connect<any, any, StateProps, any, any, any>(mapStateToProps)(Footer);
