// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { getFavicons } from '@leasen_nl/listo/src/store/siteSettings/selectors';
import {
  appleTouchIcon as appleTouchIcon180,
  favicon16,
  favicon32,
  android192,
  mstile144,
  safariPinnedTab,
  favicon,
  manifest,
  browserconfig,
} from '../../assets/favicons';

type StateProps = {|
  favicons: any,
|};

const Favicons = ({ favicons }: StateProps) => {
  const faviconData = !favicons ? {
    appleTouchIcon180,
    favicon16,
    favicon32,
    android192,
    mstile144,
    safariPinnedTab,
    favicon,
  } : favicons;
  return (
    <Helmet>
      <link rel="apple-touch-icon" sizes="180x180" href={faviconData.appleTouchIcon180} />
      <link rel="apple-touch-icon-precomposed" sizes="180x180" href={faviconData.appleTouchIcon180} />
      <link rel="apple-touch-startup-image" href={faviconData.appleTouchIcon180} />
      <link rel="icon" type="image/png" sizes="16x16" href={faviconData.favicon16} />
      <link rel="icon" type="image/png" sizes="32x32" href={faviconData.favicon32} />
      <link rel="icon" type="image/png" sizes="192x192" href={faviconData.android192} />
      <link rel="mask-icon" href={faviconData.safariPinnedTab} color="#01457d" />
      <link rel="shortcut icon" href={faviconData.favicon} />
      <link rel="manifest" href={manifest} />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="msapplication-TileImage" content={faviconData.mstile144} />
      <meta name="msapplication-config" content={browserconfig} />
    </Helmet>
  );
};

const mapStateToProps = (state): StateProps => ({
  favicons: getFavicons(state),
});

export default connect<any, any, StateProps, any, any, any>(mapStateToProps)(Favicons);
