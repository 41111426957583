// @flow

import React, { Component } from 'react';
import type { Node } from 'react';
import { ThemeProvider as Provider } from 'styled-components';
import { connect } from 'react-redux';
import { getTheme } from '@leasen_nl/listo/src/store/routes';
import { resolveTheme } from '../../helpers';

import {
  justleaseTheme,
  justleasebeTheme,
  carrefourTheme,
  mediamarktTheme,
  telegraafTheme,
  plusTheme,
  terbergbusinessTheme,
} from '../../theme';

type OwnProps = {|
  children: Node,
|};

type StateProps = {|
  theme: ?string,
|};

type Props = {|
  ...OwnProps,
  ...StateProps,
|};

class ThemeProvider extends Component<Props> {
  loadTheme = () => {
    const { theme } = this.props;
    // @TODO when we have webpack4?
    // (async () => {
    //   const { default: carrefourTheme } =
    //     await import(/* webpackChunkName: "carrefourTheme" */'../../theme/carrefour.theme');
    //   console.log(carrefourTheme, this);
    //   this.setState({
    //     theme: carrefourTheme,
    //   });
    // })();

    switch (theme) {
      case 'carrefour':
        return carrefourTheme;
      case 'mediamarkt':
        return mediamarktTheme;
      case 'telegraaf':
        return telegraafTheme;
      case 'justlease':
        return justleaseTheme;
      case 'justleasebe':
        return justleasebeTheme;
      case 'plus':
        return plusTheme;
      case 'terbergbusiness':
        return terbergbusinessTheme;
      default:
        return resolveTheme();
    }
  }

  render() {
    const { children } = this.props;
    return (
      <Provider theme={() => this.loadTheme()}>
        {children}
      </Provider>
    );
  }
}

const mapStateToProps = (state: *): StateProps => ({
  theme: getTheme(state),
});

export default connect<any, OwnProps, StateProps, any, any, any>(
  mapStateToProps,
)(ThemeProvider);
