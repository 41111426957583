// @flow

import type { Breakpoints } from '../../types/breakpoints';

const pxToEm = (px: number, rem: number = 16) => px / rem;

const reducer: Function = (
  acc: Breakpoints,
  value: [$Keys<Breakpoints>, number],
): { [$Keys<Breakpoints>]: number } => ({
  ...acc,
  [value[0].valueOf()]: pxToEm(value[1]),
});

export const breakpointsPx: Breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const breakpointKeys: Array<$Keys<Breakpoints>> = Object.keys(breakpointsPx);

export const ACFBreakpoints = [
  ...breakpointKeys,
  'default',
];

const breakpointsEm = Object.entries(breakpointsPx)
  .reduce(reducer, breakpointsPx);

export default breakpointsEm;
