// @flow

import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@leasen_nl/listo/src/helpers';
import formatTelephoneLink from '@leasen_nl/listo/src/helpers/formatTelephoneLink';
import type { Theme } from '@types/index';
import WrapperLink from '../wrapperLink';
import Text from '../text';

type Props = {
  label: string,
  url?: ?string,
  bullet?: boolean,
  icon: string,
};

type IconProps = {
  bullet: boolean,
};

const generateUrl = (text: string, type: string) => {
  switch (type) {
    case 'telephone':
      return formatTelephoneLink(text);
    case 'envelope':
      return `mailto:${text}`;
    default:
      return text;
  }
};

const Icon = styled<any, Theme, IconProps>('i')`
  font-size: 1.3rem;
  padding-right: .8rem;
  line-height: 0;
  ${props => props.bullet && css`
    position: relative;
    &::after {
      background-color: ${props.theme.topMenu.openColor};
      border-radius: 50%;
      border: 1.5px solid ${props.theme.topMenu.backgroundColor};
      content: '';
      display: block;
      height: 0.5rem;
      width: 0.5rem;
      position: absolute;
      top: -.3rem;
      left: -.3rem;
      ${media.md(css`
        display: none;
      `)}
    }
  `};
  &:before {
    vertical-align: middle;
  }
`;

const TopMenuItem = ({
  label,
  bullet = false,
  icon,
  url,
}: Props) => (
  <WrapperLink href={url || generateUrl(label, icon)} bullet={bullet}>
    <Icon bullet={bullet} className={`icon-${icon}`} />
    <Text>{label}</Text>
  </WrapperLink>
);

TopMenuItem.defaultProps = {
  bullet: false,
  url: null,
};

export default TopMenuItem;
