const supportsIntersectionObserver = () => {
  if ('IntersectionObserver' in window
    && 'IntersectionObserverEntry' in window
    && 'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
    // Minimal polyfill for Edge 15's lack of `isIntersecting`
    // See: https://github.com/w3c/IntersectionObserver/issues/211
    if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
      Object.defineProperty(window.IntersectionObserverEntry.prototype,
        'isIntersecting', {
          get: () => this.intersectionRatio > 0,
        });
    }
    return true;
  }

  return false;
};

const supportsIntl = locales => {
  // Check whether Intl exists at all
  if (!window.Intl || typeof window.Intl !== 'object') {
    return false;
  }

  // Check whether all constructors are present
  const intlConstructors = [
    // Intl.Collator, // collator doesn't support
    Intl.DateTimeFormat,
    Intl.NumberFormat,
  ].filter(intlConstructor => intlConstructor);

  if (intlConstructors.length !== 2) {
    return false;
  }

  if (Array.isArray(locales)) {
    return intlConstructors.every(intlConstructor => (
      intlConstructor.supportedLocalesOf(locales).length === locales.length
    ));
  }

  return true;
};

/**
 * Figure out which polyfills needs loading
 */
const loadPolyFills = async () => {
  if (!supportsIntersectionObserver()) {
    await import(/* webpackChunkName: "intersection-observer-polyfill" */'intersection-observer');
  }

  if (!supportsIntl(['nl', 'fr'])) {
    await import(/* webpackChunkName: "intl-polyfill" */'./intl');
  }
};

export default loadPolyFills;
