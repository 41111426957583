// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import { Box } from '@leasen_nl/listo/src/components/grid';
import type { Theme } from '@types/index';

const StyledBox = styled<typeof Box, Theme, _>(Box)`
  border-bottom: 1px solid ${props => props.theme.footer.horizontalLine};

  ${media.md(css`
    align-items: center;
    display: flex;
    padding-bottom: 1rem;
  `)}

  ${media.lg(css`
    border-style: none;
    display: block;
    padding-left: 2rem;
  `)}
`;

export default StyledBox;
