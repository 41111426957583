// @flow

import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import reducers from '@leasen_nl/listo/src/store';

const createRootReducer = (history: any) => combineReducers<Object, Object>({
  ...reducers,
  form: formReducer,
  router: connectRouter(history),
});

export default createRootReducer;
