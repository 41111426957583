// @flow

import React from 'react';
import styled from 'styled-components';
import type { FormProps } from 'redux-form';
import { breakpointsToArray } from '@leasen_nl/listo/src/helpers';
import type { NewsLetter } from '@leasen_nl/listo/src/types/settings';
import { ACFFormidable } from '@leasen_nl/listo/src/components/formidable';
import ActionButton from '@leasen_nl/listo/src/components/actionButton';
import { Text } from '@leasen_nl/listo/src/components/form/labelText';
import Input from '@leasen_nl/listo/src/components/form/input';
import type { Theme } from '@types/index';
import {
  Header,
  StyledBox,
  StyledWysiwyg,
} from './components';

type Props = {
  ...FormProps,
  newsletter: {
    ...NewsLetter,
    form_data: any,
  },
  className?: string,
};

const dimensions = {
  default: 1,
  lg: 0.4,
};

const Newsletter = ({
  newsletter,
  className,
}: Props) => (
  <StyledBox
    width={breakpointsToArray(dimensions)}
    className={className}
  >
    <div>
      {newsletter.newsletter_title && (
        <Header>{newsletter.newsletter_title}</Header>
      )}
      {newsletter.newsletter_subtitle && (
        <StyledWysiwyg
          content={newsletter.newsletter_subtitle}
          template="default"
        />
      )}
    </div>
    {newsletter.form_data && (
      <ACFFormidable
        form_data={newsletter.form_data}
      />
    )}
  </StyledBox>
);

Newsletter.defaultProps = {
  className: '',
};

export default styled<typeof Newsletter, Theme, _>(Newsletter)`
  form {
    display: flex;
    flex-direction: row;
    padding: 1.375rem 0;
    div {
      display: inline;
      width: auto;
    }
    label {
      display: inline;
    }
  }
  ${/* sc-custom 'input' */Input} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  ${/* sc-custom 'form div div' */Text} {
    display: none;
  }
  ${/* sc-custom 'button' */ActionButton} {
    height: ${props => props.theme.footer.inputField.height};
    min-width: auto;
    align-self: auto;
    padding: 0 1rem;
    background-color: ${props => props.theme.footer.button.backgroundColor};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: ${props => props.theme.footer.button.color};
    border: 1px solid ${props => props.theme.footer.button.borderColor};
    cursor: pointer;
    &:hover {
      background-color: ${props => props.theme.footer.button.hoverColor};
      border: 1px solid ${props => props.theme.footer.button.hoverColor};
    }
  }
`;
