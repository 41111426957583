// @flow

import type { PosttypeMap } from '@leasen_nl/listo/src/types';
import Page from '@leasen_nl/listo/src/components/page';
import ConfigurationPage from './components/configurationPage';
import OrderPage from './components/orderPage';
import ModelInfoPage from './components/modelInfoPage';

const posttypeMapping: PosttypeMap = {
  page: Page,
  modellen: ModelInfoPage,
  cmconfiguratorpage: ConfigurationPage,
  cmformpage: OrderPage,
  cmconfirmpage: Page,
};

export default posttypeMapping;
