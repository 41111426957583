/* eslint-disable import/no-cycle */
// @flow

import Loadable from 'react-loadable';

/**
 * React loadable is initialized in the ./components/configurationPage file.
 * The reason for this is that the component map is being used in the configurationPage.
 * The component map causes the configurationPage bundle to be merged with the main bundle.
 */
import ConfigPage from './components/configurationPage';

const loading = () => null;
const LoadableComparePage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "compareTabe" */'./components/pdbCompare'),
  loading,
});

const LoadableAssortment = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "assortment" */'./components/assortment'),
  loading,
});

const LoadableCalculator = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "calculator" */'./components/calculator'),
  loading,
});

const LoadableFAQItemPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "faq-item" */'./components/faqItemPage'),
  loading,
});

const FaqCategory = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "faq-category" */'./components/faqCategoryPage'),
  loading,
});

const LoadablePage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "page" */'@leasen_nl/listo/src/components/page'),
  loading,
});

const LoadableConfirmPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "page" */'./components/loadableConfirmPage'),
  loading,
});

const LoadableOrderPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "order-page" */'./components/orderPage'),
  loading,
});

const LoadableNewsPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "news-page" */'./components/newsPage'),
  loading,
});

const LoadableNewsArticlePage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "news-article" */'./components/newsArticlePage'),
  loading,
});

const LoadableBlogPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "blog-page" */'./components/blogPage'),
  loading,
});

const LoadableBlogArticlePage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "blog-article" */'./components/blogArticlePage'),
  loading,
});

const LoadableTop10Page = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "top-10" */'./components/top10Page'),
  loading,
});

const LoadableReviewPage = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "review-page" */'./components/reviewPage'),
  loading,
});

const LoadableTerbergBusinessCompanyInformation = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "terbergbusiness-company-information" */'./templates/terbergBusiness/companyInformation/index'),
  loading,
});

const LoadableJustLeaseLeadGenerator = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "justLease-lead-generator" */'./templates/justLease/leadGenerator/index'),
  loading,
});

const LoadableGenericPageNotFound = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "generic-page-not-found" */'./templates/generic/pageNotFound/index'),
  loading,
});

const LoadableHomepageTerbergBusinessLeaseGroupNL = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "terbergbusiness-homepage-terbergbusinessleasegroup-nl" */'./templates/terbergBusiness/homepageTerbergBusinessLeaseGroupNL/index'),
  loading,
});

const LoadableBrandDetails = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "brand-details" */'./templates/justLease/brandDetails/index'),
  loading,
});

const LoadableModelInfo = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "model-info" */'./templates/justLease/modelInfo/index'),
  loading,
});

const templateMapping = () => {
  const acfMapping = {
    assortment: LoadableAssortment,
    '404': LoadablePage, // eslint-disable-line quote-props
    calculator: LoadableCalculator,
    config: ConfigPage,
    confirm: LoadableConfirmPage,
    'faq-category': FaqCategory,
    'faq-item': LoadableFAQItemPage,
    faq: LoadablePage,
    news: LoadableNewsArticlePage,
    'news-overview': LoadableNewsPage,
    blog: LoadableBlogArticlePage,
    'blog-overview': LoadableBlogPage,
    page: LoadablePage,
    // 'model-info': LoadablePage,
    order: LoadableOrderPage,
    compare: LoadableComparePage,
    'top10-overview': LoadableTop10Page,
    'review-overview': LoadableReviewPage,
  };

  const genericMapping = {
    'page-not-found': LoadableGenericPageNotFound,
  };

  const staticJustLeaseMapping = {
    'lead-generator': LoadableJustLeaseLeadGenerator,
    'models-by-brand': LoadableBrandDetails,
    'model-info-standard': LoadableModelInfo,
  };

  const staticTerbergBusinessMapping = {
    'homepage-terbergbusinessleasegroup-nl': LoadableHomepageTerbergBusinessLeaseGroupNL,
    'company-information': LoadableTerbergBusinessCompanyInformation,
  };

  switch (process.env.LISTO_THEME) {
    case 'justlease':
      return { ...acfMapping, ...genericMapping, ...staticJustLeaseMapping };
    case 'terbergbusiness':
      return { ...acfMapping, ...genericMapping, ...staticTerbergBusinessMapping };
    default:
      return { ...acfMapping, ...genericMapping };
  }
};

export default templateMapping();
