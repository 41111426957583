// @flow
import { css } from 'styled-components';

export const grid = () => (css`
  display: -ms-grid; /* stylelint-disable-line value-no-vendor-prefix */
  display: grid;
`);

export const gridTemplateColumns = (value: string = 'none') => (
  css`
    -ms-grid-columns: ${value}; /* stylelint-disable-line property-no-vendor-prefix */
    grid-template-columns: ${value};
  `
);

export const gridTemplateRows = (value: string = 'none') => (
  css`
    -ms-grid-rows: ${value}; /* stylelint-disable-line property-no-vendor-prefix */
    grid-template-rows: ${value};
  `
);

export const gridArea = (area: string) => {
  const splitValues = area
    .split('/')
    .map(val => parseInt(val, 10));

  if (splitValues.length !== 4) {
    throw new Error('gridArea function needs proper grid-area value like: 1 / 2 / 3 / 4');
  }

  const msValues = splitValues.reduce((accu, value, index) => {
    let result = accu;
    switch (index) {
      case 0:
        result += `-ms-grid-row: ${value};`;
        break;
      case 1:
        result += `-ms-grid-column: ${value};`;
        break;
      case 2:
        result += `-ms-grid-row-span: ${value - splitValues[0]};`;
        break;
      case 3:
        result += `-ms-grid-column-span: ${value - splitValues[1]};`;
        break;
      default:
    }
    return result;
  }, '');

  return css`
    ${msValues}
    grid-area: ${area};
  `;
};
