// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { SiteSettings, ContactSettings, Nav } from '@leasen_nl/listo/src/types/settings';
import getSiteSettings from '@leasen_nl/listo/src/store/siteSettings/selectors';
import { getUiSetting } from '@leasen_nl/listo/src/store/uiStore/selectors';
import getContactSettings from '@leasen_nl/listo/src/store/contactSettings/selectors';
import { getHeaderMenu } from '@leasen_nl/listo/src/store/menus';
import TopMenu from '../topMenu';
import MainMenu from '../mainMenu';
import HeaderStyled from './header.styled';

export type StateProps = {|
  headerMenu: Nav,
  contactSettings: ContactSettings,
  siteSettings: SiteSettings,
  subMenuOpen: boolean,
  hamburgerOpen: boolean,
|};

type Props = StateProps;

const Header = ({
  subMenuOpen,
  hamburgerOpen,
  headerMenu,
  contactSettings: {
    open_for_business_label,
    general_number,
    general_email_address,
  },
  siteSettings: {
    my_url,
    my_label,
  },
}: Props) => (
  <HeaderStyled data-cy="site-header" subMenuOpen={Boolean(subMenuOpen) || Boolean(hamburgerOpen)}>
    <TopMenu
      openForBusinessLabel={open_for_business_label}
      generalNumber={general_number}
      generalEmailAddress={general_email_address}
      myUrl={my_url}
      myLabel={my_label}
    />
    <MainMenu
      generalEmailAddress={general_email_address}
      generalNumber={general_number}
      myUrl={my_url}
      nav={headerMenu}
    />
  </HeaderStyled>
);


const mapStateToProps = (state: *): StateProps => ({
  siteSettings: getSiteSettings(state),
  contactSettings: getContactSettings(state),
  headerMenu: getHeaderMenu(state),
  subMenuOpen: getUiSetting(state, 'subMenuOpen'),
  hamburgerOpen: getUiSetting(state, 'hamburgerOpen'),
});

export default connect<any, any, StateProps, any, any, any>(
  mapStateToProps,
)(Header);
