// @flow

import React, { type ComponentType } from 'react';
import OccasionsComponent from '@leasen_nl/listo/src/components/pdbInfo/occasions';
import withNumberOfOccasions from '@leasen_nl/listo/src/components/pdbInfo/withNumberOfOccasions';

/* eslint-disable react/require-default-props */

type Props = {
  numberOfOccasions?: number,
  linkToOccassions?: string,
};

const Occasions: ComponentType<Props> = ({
  numberOfOccasions,
  linkToOccassions,
}: Props) => {
  if (!numberOfOccasions || !linkToOccassions) {
    return null;
  }

  return (
    <OccasionsComponent
      numberOfOccasions={numberOfOccasions}
      linkToOccassions={linkToOccassions}
    />
  );
};

export default withNumberOfOccasions<Props>(Occasions);
