// @flow

import React from 'react';
import { ACFButton } from '@leasen_nl/listo/src/components/button';
import type { ACFProps } from '@leasen_nl/listo/src/components/button';

const Button = (props: ACFProps) => (
  <ACFButton {...props} />
);

export default Button;
