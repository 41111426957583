// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import type { Theme } from '@types/index';

type Props = {
  bullet: boolean,
};

const styleMedium = css`
  flex-basis: auto;

  &:first-of-type {
    border-width: ${(props: Props) => (props.bullet ? '0 0 0 1px' : '0')};
  }
`;

const styleLarge = css`
  flex-grow: 0;
  padding: 0.5rem 1.5rem;
`;

const WrapperLink = styled<any, Theme>('a')`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.topMenu.textColor};
  border-style: solid;
  border-color: ${props => props.theme.topMenu.borderColor};
  border-width: 0 0 0 1px;

  &:first-of-type {
    border-width: 0;
  }

  ${media.md(styleMedium)}
  ${media.lg(styleLarge)}
`;

export default WrapperLink;
