// @flow

import styled from 'styled-components';
import type { Theme } from '@types/index';

type Props = {
  languageSelectorOpen: boolean,
};

const Dropdown = styled<any, Theme, Props>('div')`
  display: ${props => (props.languageSelectorOpen ? 'block' : 'none')};
  position: absolute;
  z-index: 99999;
  top: ${props => props.theme.topMenu.height};
  right: 0;
`;

export default Dropdown;
