// @flow

import React from 'react';
import { InView } from 'react-intersection-observer';
import type { Logo as LogoType } from '@leasen_nl/listo/src/types/settings';
import { Wrapper, Logo } from './atoms';

type Props = {
  logos: Array<LogoType>,
};

const Logos = ({ logos }: Props) => (
  <InView>
    {({ inView, ref }) => (
      <div ref={ref}>
        {inView && (
          <Wrapper>
            {logos && logos.map((logo: LogoType) => (
              <div key={logo.logo}>
                { !logo.link
                  && <Logo alt={logo.alt} src={logo.logo} title={logo.alt} />}
                { logo.link
                  && (
                  <a
                    href={logo.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={logo.alt}
                  >
                    <Logo alt={logo.alt} src={logo.logo} />
                  </a>
                  )}
              </div>
            ))}
          </Wrapper>
        )}
      </div>
    )}
  </InView>
);

export default Logos;
