// @flow

import { splitAtMoreMacro, type Macro } from '@leasen_nl/listo/src/helpers/macros';
import {
  getMake,
  getModel,
  getManufactured,
  getOdometer,
  getLicense,
  getDamageDescription,
  getTransmission,
  getPower,
  getFueltype,
  getFirstColorName,
  getFirstPackageName,
} from '@leasen_nl/listo/src/store/pdb/selectors';
import { formValueSelector } from 'redux-form';
import { getCartPrice, getLoginHash } from '@leasen_nl/listo/src/store/configuration';
import { formName } from './components/orderPage';
import svgMapping from './svgMapping';

type SvgMapping = typeof svgMapping;

type Contract = {
  macros: Array<Macro>,
} & SvgMapping;

const selector = formValueSelector(formName);

const WPcontract: Contract = {
  macros: [
    {
      macro: '[make]',
      pattern: '(\\[make\\])',
      description: 'Het merk van de active auto op de pagina',
      value: state => getMake(state),
    },
    {
      macro: '[model]',
      pattern: '(\\[model\\])',
      description: 'Het model van de active auto op de pagina',
      value: state => getModel(state),
    },
    {
      macro: '[firstnameFromOrderForm]',
      pattern: '(\\[firstnameFromOrderForm\\])',
      description: 'De voornaam van de persoon die het bestelformulier zojuist heeft ingevuld',
      value: state => selector(state, 'first_name'),
    },
    {
      macro: '[lastnameFromOrderForm]',
      pattern: '(\\[lastnameFromOrderForm\\])',
      description: 'De achternaam van de persoon die het bestelformulier zojuist heeft ingevuld',
      value: state => selector(state, 'last_name'),
    },
    {
      macro: '[salutationFromOrderForm]',
      pattern: '(\\[salutationFromOrderForm\\])',
      description: 'De sekse van de persoon die het bestelformulier zojuist heeft ingevuld',
      value: state => selector(state, 'salutation'),
    },
    {
      macro: '[emailFromOrderForm]',
      pattern: '(\\[emailFromOrderForm\\])',
      description: 'Het e-mail adres van de persoon die het bestelformulier zojuist heeft ingevuld',
      value: state => selector(state, 'email'),
    },
    {
      macro: '[totalMonthlyCost]',
      pattern: '(\\[totalMonthlyCost\\])',
      description: 'De totale maandprijs van de bestelling die zojuist geplaatst is',
      value: state => getCartPrice(state),
    },
    {
      macro: '[loginhash]',
      pattern: '(\\[loginhash\\])',
      description: 'De login hash om automatisch in te loggen in my.justlease',
      value: state => getLoginHash(state),
    },
    {
      macro: '[manufactured]',
      pattern: '(\\[manufactured\\])',
      description: 'Het bouwjaar',
      value: state => getManufactured(state),
    },
    {
      macro: '[odometer]',
      pattern: '(\\[odometer\\])',
      description: 'De kilometerstand',
      value: state => getOdometer(state),
    },
    {
      macro: '[license]',
      pattern: '(\\[license\\])',
      description: 'Het kenteken',
      value: state => getLicense(state),
    },
    {
      macro: '[damages]',
      pattern: '(\\[damages\\])',
      description: 'Eventuele schades',
      value: state => getDamageDescription(state),
    },
    {
      macro: '[transmission]',
      pattern: '(\\[transmission\\])',
      description: 'De transmissie',
      value: state => getTransmission(state),
    },
    {
      macro: '[power]',
      pattern: '(\\[power\\])',
      description: 'Het vermogen',
      value: state => getPower(state),
    },
    {
      macro: '[fueltype]',
      pattern: '(\\[fueltype\\])',
      description: 'Het brandstoftype',
      value: state => getFueltype(state),
    },
    {
      macro: '[color]',
      pattern: '(\\[color\\])',
      description: 'De kleur',
      value: state => getFirstColorName(state),
    },
    {
      macro: '[packagename]',
      pattern: '(\\[packagename\\])',
      description: 'De uitvoering',
      value: state => getFirstPackageName(state),
    },
    splitAtMoreMacro,
  ],
  ...svgMapping,
};

export default WPcontract;
