// @flow

import React from 'react';
import type { Nav } from '@leasen_nl/listo/src/types/apiRoute';
import Footer from '@leasen_nl/listo/src/components/menu/components/footer';

type Props = {
  nav: Nav,
};

const NavContainer = ({ nav }: Props) => <Footer nav={nav} />;

export default NavContainer;
