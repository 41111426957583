// @flow

import React from 'react';
import { ACFCategoryAccordion } from '@leasen_nl/listo/src/components/faq';
import type { ACFProps } from '@leasen_nl/listo/src/components/faq/categoryAccordion/types';

const FAQCategoryAccordion = (props: ACFProps) => (
  <ACFCategoryAccordion {...props} />
);

export default FAQCategoryAccordion;
