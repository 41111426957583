// @flow

import React from 'react';
import { Wrapper, Bullet, Text } from './atoms';

type Props = {
  label: string,
  open: boolean,
};

const Open = ({ label, open }: Props) => {
  if (!open) return null;
  return (
    <Wrapper>
      <Bullet />
      <Text>{label}</Text>
    </Wrapper>
  );
};

export default Open;
