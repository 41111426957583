// @flow

import styled from 'styled-components';
import type { Theme } from '@types/index';
import { gridArea } from '@helpers/index';

const AnchorIcon = styled<any, Theme>('a')`
  ${gridArea('1 / 1 / 3 / 2')}
  align-self: center;
  border: 1px solid ${props => props.theme.footer.icon.borderColor};
  border-radius: 50%;
  height: 1.125rem;
  width: 1.125rem;
  padding: 0.5rem;
  text-decoration: none;
  color: ${props => props.theme.footer.icon.fillColor};
`;

export default AnchorIcon;
