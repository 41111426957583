// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import type { Theme } from '@types/index';

const Heading = styled<any, Theme>('h1')`
  color: ${props => props.theme.orderPage.header.color};
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 0 2rem 0.25rem;

  ${media.sm(css`
    padding: 0;
  `)}

  ${media.md(css`
    font-size: 1.75rem;
  `)}

  ${media.lg(css`
    font-size: 2.125rem;
  `)}
`;

export default Heading;
