// @flow

import { css } from 'styled-components';
import type { StyledComponentProps, BackgroundColor } from '../types';

type StyleProps = StyledComponentProps & {
  hasOverlay?: boolean,
};

export const getDiagonal = (color: BackgroundColor = 'default') => css`
  padding-bottom: calc(1rem + 6vw);
  &::after {
    content: "";
    display: block;
    height: 0;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    box-sizing: content-box;
    border-bottom: 6vw solid ${(props: StyledComponentProps) => props.theme.backgroundColor[color || 'default']};
    border-left: 100vw solid transparent;
  }
`;

export const getShadow = (props: StyleProps) => css`
  ${props.hasOverlay && css`
    &::before {
      background: linear-gradient(to right, rgba(35, 35, 35, 0.8), rgba(255, 255, 255, 0) 65%);
      position: absolute;
      left: 0;
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
    }
  `
}`;
