// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import type { Theme } from '@types/index';

export const Wrapper = styled<any, Theme>('div')`
  background-color: ${props => props.theme.topMenu.backgroundColor};
`;

export const Row = styled<any, Theme>('div')`
  display: flex;
  font-size: 14px;
  height: ${props => props.theme.topMenu.height};

  ${media.md(css`
    justify-content: space-between;
  `)}

  ${media.lg(css`
    justify-content: flex-end;
  `)}
`;

export default null;
