// @flow

import styled from 'styled-components';
import { LinkStyled } from '@leasen_nl/listo/src/components/link';
import type { Theme } from '@types/index';

const Link = styled<any, Theme>(LinkStyled)`
  color: ${props => props.theme.footer.textColor};

  &:hover {
    text-decoration: none;
  }
`;

export default Link;
