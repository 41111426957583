// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import type { Theme } from '@types/index';

const Logo = styled<any, Theme>('img')`
  max-height: 4.25rem;
  ${media.lg(css`
    margin: 1.2rem;
  `)}
`;

export default Logo;
