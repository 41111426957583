// @flow

import styled from 'styled-components';

const Text = styled<any, any>('div')`
  display: inline;
  white-space: nowrap;
  line-height: 1em;
`;

export default Text;
