// @flow

import styled, { css } from 'styled-components';
import type { Theme } from '@types/index';
import {
  grid,
  gridTemplateRows,
  gridTemplateColumns,
  media,
} from '@helpers/index';

const Wrapper = styled<any, Theme>('section')`
  ${grid()}
  ${gridTemplateRows('1fr 1fr 1fr 1fr')}
  ${gridTemplateColumns('1fr')}

  ${media.md(css`
    ${gridTemplateRows('1fr 1fr')}
    ${gridTemplateColumns('1fr 1fr')}
  `)}
`;

export default Wrapper;
