// @flow

import breakpoints, { breakpointsPx, breakpointKeys } from './breakpoints';

export {
  breakpointsPx,
  breakpointKeys,
};

export default breakpoints;
