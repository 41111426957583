// @flow
import React from 'react';
import { ACFIconText } from '@leasen_nl/listo/src/components/iconText';
import type { ACFProps } from '@leasen_nl/listo/src/components/iconText';

type Props = ACFProps & {
  icon: string,
};

const IconText = (props: Props) => (
  <ACFIconText
    {...props}
  />
);

export default IconText;
