// @flow

import React from 'react';
import { ACFReview } from '@leasen_nl/listo/src/components/review';
import type { ACFProps } from '@leasen_nl/listo/src/components/review';

const Review = (props: ACFProps) => (
  <ACFReview
    {...props}
  />
);

export default Review;
