// @flow

import { css, type CSSRules } from 'styled-components';
import breakpoints from '../breakpoints';

// Iterate through the sizes and create a media template
const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (str: CSSRules) => css`
    @media (min-width: ${breakpoints[label]}em) {
      ${str}
    }
  `;

  return acc;
}, {});

export default media;
