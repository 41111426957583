// @flow

import { css } from 'styled-components';
import globalListoCss from '@leasen_nl/listo/src/themes/global';
// load base globals
import { globalCss as baseGlobalCss } from './base.theme';
// load justlease fonts injection
import { globalCss as justleaseGlobalCss } from './justlease.theme';
// load justlease fonts injection
import { globalCss as justleasebeGlobalCss } from './justleasebe.theme';
// load carrefour fonts injection
import { globalCss as carrefourGlobalCss } from './carrefour.theme';
// load mediamarkt fonts injection
import { globalCss as mediamarktGlobalCss } from './mediamarkt.theme';
// load telegraaf fonts injection
import { globalCss as telegraafGlobalCss } from './telegraaf.theme';
// load Plus fonts injection
import { globalCss as plusGlobalCss } from './plus.theme';
// load Terbergbusiness fonts injection
import { globalCss as terbergbusinessGlobalCss } from './terbergbusiness.theme';
// load Privateleasecom fonts injection
import { globalCss as privateleasecomGlobalCss } from './privateleasecom.theme';

export default css`
  ${globalListoCss}
  ${baseGlobalCss}
  ${justleaseGlobalCss}
  ${justleasebeGlobalCss}
  ${carrefourGlobalCss}
  ${mediamarktGlobalCss}
  ${telegraafGlobalCss}
  ${plusGlobalCss}
  ${terbergbusinessGlobalCss}
  ${privateleasecomGlobalCss}
`;
