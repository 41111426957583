// @flow

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getContactOptions } from '@leasen_nl/listo/src/store/footerSettings/selectors';
import { breakpointsToArray } from '@leasen_nl/listo/src/helpers';
import type { ContactOption } from '@leasen_nl/listo/src/types/settings';
import { Box } from '@leasen_nl/listo/src/components/grid';
import { mapContactOptions } from '@leasen_nl/listo/src/store/settings/selectors';
import type { Theme } from '@types/index';
import {
  StyledBox,
  AnchorIcon,
  AnchorText,
  SubText,
  Wrapper,
} from './atoms';

const Icon = styled<any, Theme, _>('i')`
  font-size: 1.1rem;
`;

const dimensions = {
  default: 1,
  md: 0.5,
};

type StateProps = {|
  contactOptions: Array<ContactOption & {
    value: string,
    href: ?string,
  }>,
|};

type Props = StateProps;

const ContactOptions = ({ contactOptions }: Props) => (
  <Wrapper>
    {contactOptions && contactOptions.map((option, index) => (
      <StyledBox
        key={option.icon.value}
        sortOrder={index + 1}
        mt="1.375rem"
        width={breakpointsToArray(dimensions)}
      >
        <AnchorIcon href={option.href}>
          <Box>
            <Icon className={`icon-${option.icon.value}`} />
          </Box>
        </AnchorIcon>
        {option.subtitle && option.subtitle !== ' ' && <SubText>{option.subtitle}</SubText>}
        <AnchorText href={option.href}>
          {option.mode === 'facebook' ? 'Facebook' : option.value}
        </AnchorText>
      </StyledBox>
    ))}
  </Wrapper>
);

const mapStateToProps = (state): StateProps => ({
  contactOptions: mapContactOptions(state)(getContactOptions(state)),
});

export default connect<any, any, StateProps, any, any, any>(mapStateToProps)(ContactOptions);
