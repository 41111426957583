// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import { Flex } from '@leasen_nl/listo/src/components/grid';
import type { Theme } from '@types/index';

const StyledFlex = styled<any, Theme>(Flex)`
  justify-content: center;
  flex-wrap: wrap;
  color: ${props => props.theme.footer.textColor};
  font-size: 0.875rem;
  margin-top: 1.625rem;
  ${media.md(css`
    flex-wrap: no-wrap;
    margin-top: 1rem;
  `)}
  ${media.lg(css`
    margin-top: 0;
  `)}
`;

export default StyledFlex;
