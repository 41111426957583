// @flow

import styled, { css } from 'styled-components';
import { Box } from '@leasen_nl/listo/src/components/grid';
import type { Theme } from '@types/index';
import { media } from '@helpers/index';

const Wrapper = styled<typeof Box, Theme>(Box)`
  margin-bottom: 2.5rem;
  width: 100%;

  ${media.lg(css`
    width: 60%;
  `)}
`;

export default Wrapper;
