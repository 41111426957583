// @flow

import React from 'react';
import { compose } from 'redux';
import withOpeningHours from '@leasen_nl/listo/src/components/withOpeningHours';
import type { OpeningHours } from '@leasen_nl/listo/src/types';
import { Container } from '@leasen_nl/listo/src/components/grid';

import { Wrapper, Row } from './topMenu.styled';
import {
  TopMenuItem,
  Open,
  TrustMarkPrivateLeaseLogo,
  LanguageSelector,
} from './atoms';

type OwnProps = {
  openForBusinessLabel: string,
  generalNumber: string,
  generalEmailAddress: string,
  myUrl: string,
  myLabel: string,
  openingHours: OpeningHours, // from withOpeningHours
};

type Props = OwnProps;

const TopMenu = ({
  openForBusinessLabel,
  generalNumber,
  generalEmailAddress,
  myUrl,
  myLabel,
  openingHours: { open },
}: Props) => (
  <Wrapper>
    <Container notOnSm notOnMd>
      <Row>
        <Open label={openForBusinessLabel} open={open} />
        {generalNumber && <TopMenuItem icon="telephone" label={generalNumber} bullet={open} />}
        {generalEmailAddress && <TopMenuItem icon="envelope" label={generalEmailAddress} />}
        {myLabel && <TopMenuItem icon="user" url={myUrl} label={myLabel} />}
        <LanguageSelector />
        <TrustMarkPrivateLeaseLogo />
      </Row>
    </Container>
  </Wrapper>
);

export default compose(
  withOpeningHours,
)(TopMenu);
