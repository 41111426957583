// @flow

import styled, { css } from 'styled-components';
import { media } from '@leasen_nl/listo/src/helpers';
import type { Theme } from '@types/index';

export const InnerWrapper = styled<any, Theme>('div')`
  background-color: ${props => props.theme.mainMenu.backgroundColor};
  box-shadow: 0 4px 8px rgba(0,0,0,.28);
  box-sizing: border-box;
  padding: 0;
  transition: height 0.2s linear 0.1s;
`;

export const Row = styled<any, Theme>('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
`;

export const DesktopWrapper = styled<any, Theme>('section')`
  display: none;
  padding-left: 1rem;
  width: 100%;

  ${media.lg(css`
    display: flex;
  `)};

  &:hover {
    z-index: 9998;
  }
`;

export const TouchWrapper = styled<any, Theme>('section')`
  align-items: flex-end;
  display: flex;

  ${media.lg(css`
    display: none;
  `)};
`;

export default null;
