// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import type { Theme } from '@types/index';

type Props = {
  languageSelectorOpen: boolean,
};

const WrapperLink = styled<any, Theme, Props>('a')`
  display: none;
  border-style: solid;
  border-color: ${props => (props.languageSelectorOpen ? 'transparent' : `${props.theme.topMenu.borderColor}`)};
  border-width: 0 0 0 1px;
  ${media.md(css`
    display: flex;
    flex-grow: 1;
    flex-basis: auto;
    justify-content: center;
    align-items: center;
  `)}
  ${media.lg(css`
    flex-grow: 0;
    padding: 0.5rem 1.5rem;
  `)}
`;

export default WrapperLink;
