// @flow

import styled from 'styled-components';
import type { Theme } from '@types/index';
import { gridArea } from '@helpers/index';

const SubText = styled<any, Theme>('div')`
  ${gridArea('2 / 2 / 3 / 3')}
  color: ${props => props.theme.footer.subTextColor};
  font-size: 0.875rem;

  + a {
    ${gridArea('1 / 2 / 2 / 3')}
  }
`;

export default SubText;
