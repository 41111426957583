// @flow

import styled, { css } from 'styled-components';
import media from '@leasen_nl/listo/src/helpers/media';
import type { Theme } from '@types/index';

const ActiveLanguage = styled<any, Theme>('div')`
  cursor: pointer;
  line-height: 2rem;
  text-transform: uppercase;

  ${media.lg(css`
    padding: 0.5rem 1.5rem;
  `)}
`;

export default ActiveLanguage;
