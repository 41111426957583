// @flow

import React from 'react';
import { ACFButtonBar } from '@leasen_nl/listo/src/components/buttonBar';
import type { ACFProps } from '@leasen_nl/listo/src/components/buttonBar';

const ButtonBar = (props: ACFProps) => (
  <ACFButtonBar {...props} />
);

export default ButtonBar;
