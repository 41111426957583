// @flow

import React from 'react';
import Rows from '@leasen_nl/listo/src/components/rows';
import type { ACFPage } from '@leasen_nl/listo/src/types';
import Loadable from 'react-loadable';

type Props = {
  acf: ACFPage,
};

const PdbConfig = Loadable<any, any>({
  loader: () => import(/* webpackChunkName: "pdb-config" */'./atoms/pdbConfig'),
  loading: () => null,
});

const ConfigPage = ({
  acf: {
    rows,
    configbuilder,
  },
}: Props) => ([
  <Rows key="config-rows" rows={rows} />,
  <PdbConfig key="config-builder" configBuilder={configbuilder} />,
]);

export default ConfigPage;
