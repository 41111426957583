// @flow

import { css } from 'styled-components';
import telegraaf, {
  fonts,
  FONT_DEFAULT,
  FONT_WEIGHT_DEFAULT,
} from '@leasen_nl/listo/src/themes/telegraaf';
import baseTheme from './base.theme';
import type { Theme } from '../types';

export const globalCss = css`
  ${fonts}
`;

const theme: Theme = {
  ...telegraaf,
  ...baseTheme,
  font: {
    family: FONT_DEFAULT,
    weight: FONT_WEIGHT_DEFAULT,
  },
};

export default theme;
