// @flow

import styled from 'styled-components';
import type { Theme } from '@types/index';
import { anchorStyles } from './styles';

export const Link = styled<any, Theme>('a')`
  ${anchorStyles}
`;

export default Link;
