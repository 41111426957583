// @flow

import styled from 'styled-components';
import type { Theme } from '@types/index';
import { gridArea } from '@helpers/index';

const AnchorText = styled<any, Theme>('a')`
  ${gridArea('1 / 2 / 3 / 3')}
  align-self: center;
  color: ${props => props.theme.footer.textColor};
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  word-break: break-all;
  padding-right: 0.5rem;
`;

export default AnchorText;
