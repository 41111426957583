// @flow

import justlease, {
  FONT_DEFAULT,
  FONT_WEIGHT_DEFAULT,
  BRAND_PRIMARY,
  TEXT_LIGHT,
  BRAND_PRIMARY_DARK,
} from '@leasen_nl/listo/src/themes/justlease';
import baseTheme from './base.theme';
import type { Theme } from '../types';

export const globalCss = '';

const theme: Theme = {
  ...justlease,
  ...baseTheme,
  hasDiagonal: true,
  font: {
    family: FONT_DEFAULT,
    weight: FONT_WEIGHT_DEFAULT,
  },
  footer: {
    ...baseTheme.footer,
    button: {
      backgroundColor: BRAND_PRIMARY,
      color: TEXT_LIGHT,
      borderColor: BRAND_PRIMARY,
      hoverColor: BRAND_PRIMARY_DARK,
    },
  },
};

export default theme;
