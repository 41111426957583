// @flow

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import type { Theme } from '@types/index';
import { anchorStyles } from './styles';

export const RouterLink = styled<typeof Link, Theme, _>(Link)`
  ${anchorStyles}
`;

export default RouterLink;
