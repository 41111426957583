// @flow

import styled from 'styled-components';
import type { Theme } from '@types/index';

const Header = styled<any, Theme>('header')`
  position: sticky;
  top: -${props => props.theme.topMenu.height};

  /*
  This z-index if is for the progressbar in listo, if the submenu is open,
  the header needs to win from the progressbar, if not open, the progressbar needs to win.
  The transition needs to be the same as the transition on de MainMenuItem.styled for this to work smooth
  */
  z-index: 100;
  &:hover {
    z-index: 9998;
  }
`;

export default Header;
