// @flow

import 'isomorphic-fetch';
import appendQuery from 'append-query';
import FetchError from './fetchError';
import parseEndpoint from '../parseEndpoint';
import parseSlug from '../parseSlug';


async function fetchJSON<T>({
  endpoint,
  method = 'GET',
  body,
  headers = {},
}): Promise<{ json: T, response: Response }> {
  const reqHeaders = new Headers(headers);
  reqHeaders.append('Accept', 'application/json');
  const response = await fetch(endpoint, {
    method,
    headers: reqHeaders,
    credentials: 'same-origin',
    body: body || undefined,
  });

  if (response.status >= 400 && response.status !== 404) {
    throw new FetchError({
      response: response.text,
      message: 'Error fetching data from the API',
      status: response.status,
      url: endpoint,
      body,
      headers: JSON.stringify(headers),
      serverResponse: {
        statusText: response.statusText,
        status: response.status,
      },
    });
  }

  let json;
  try {
    json = await response.json();
  } catch (e) {
    throw new FetchError({
      message: 'Error parsing the JSON response',
      status: 500,
      url: endpoint,
      body,
      headers: JSON.stringify(headers),
      serverResponse: {
        statusText: response.statusText,
        status: response.status,
      },
    });
  }

  return {
    json,
    response,
  };
}

export type Arguments = {
  method?: 'GET' | 'POST',
  auth?: string,
  body?: Object,
  endpoint: string,
  headers?: Object,
  language?: ?string,
};

/**
 * The function below enriches the fetchData function with language settings,
 * additional headers and transforms the body to a stringified JSON.
 */
async function fetchData<T>({
  endpoint,
  method = 'GET',
  auth,
  body,
  language,
  headers = {},
}: Arguments): Promise<{ json: T, response: Response }> {
  let url = parseEndpoint(endpoint);
  const stringifiedBody = body ? JSON.stringify(body) : undefined;

  // We parse the slug so we keep SEO pages
  if (endpoint.includes('?')) {
    url = appendQuery(url, {
      ...parseSlug(endpoint),
    });
  }

  if (language) {
    url = appendQuery(url, {
      language,
    });
  }

  if (method === 'GET' && body) {
    url = appendQuery(url, body);
  }

  const clonedHeaders = { ...headers };

  if (method === 'POST') {
    clonedHeaders['Content-Type'] = 'application/json';
  }

  if (auth) {
    clonedHeaders.Authorization = auth;
  }

  return fetchJSON({
    endpoint: url,
    method,
    auth,
    body: stringifiedBody,
    language,
    headers: clonedHeaders,
  });
}


export {
  fetchData,
};
