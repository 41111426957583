// @flow

import React from 'react';
import { ACFList, List as ListContainer } from '@leasen_nl/listo/src/components/list';
import type { ACFProps } from '@leasen_nl/listo/src/components/list';

const List = (props: ACFProps) => (
  <ACFList
    {...props}
  />
);

export { ListContainer };

export default List;
