// @flow

import { css } from 'styled-components';
import type { StyledComponentProps as StyledProps } from '../../types';

export const anchorStyles = css`
  text-decoration: none;
  color: ${(props: StyledProps) => props.theme.anchor.main};
  cursor: pointer;

  &:hover {
    color: ${(props: StyledProps) => props.theme.anchor.hover};
  }
  &:focus {
    color: ${(props: StyledProps) => props.theme.anchor.focus};
  }
  &:active {
    color: ${(props: StyledProps) => props.theme.anchor.active};
  }
  &:visited {
    color: ${(props: StyledProps) => props.theme.anchor.visited};
  }

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;
