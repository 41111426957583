// @flow

import React from 'react';
import {
  DesktopMenu,
  TouchMenu,
} from '@leasen_nl/listo/src/components/menu';
import type { Nav } from '@leasen_nl/listo/src/types/apiRoute';
import { Container } from '@leasen_nl/listo/src/components/grid';
import {
  InnerWrapper,
  Row,
  DesktopWrapper,
  TouchWrapper,
} from './mainMenu.styled';
import Logo from './atoms/logo';
import { Link } from '../link';

type Props = {
  nav: Nav,
};

const MainMenu = ({ nav }: Props) => (
  <InnerWrapper>
    <Container>
      <Row>
        <Link href="/">
          <Logo />
        </Link>
        <>
          <DesktopWrapper>
            <DesktopMenu nav={nav} />
          </DesktopWrapper>
          <TouchWrapper>
            <TouchMenu nav={nav} />
          </TouchWrapper>
        </>
      </Row>
    </Container>
  </InnerWrapper>
);

export default MainMenu;
