// @flow

import React, { type ComponentType } from 'react';
import type { Store } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import Listo from '@leasen_nl/listo/src/components/listo';
import { setComponentMapping } from '@leasen_nl/listo/src/context/componentMapping';
import addGlobalCss from '@leasen_nl/listo/src/helpers/addGlobalCss';
import { globalCss } from './theme';
import Justlease from './components/justlease';
import ThemeProvider from './components/themeProvider';
import componentMapping from './componentMapping';
import { resolveTheme } from './helpers';

type Props = {
  store: Store<*, *, ThunkAction>,
  Router: ComponentType<*>,
  onInit?: Function, // eslint-disable-line react/require-default-props
};

const GlobalStyle = addGlobalCss(globalCss);

const Project = ({
  store,
  Router,
  onInit,
}: Props) => (
  <>
    <GlobalStyle />
    <Listo
      store={store}
      // $FlowFixMe
      theme={resolveTheme()}
      onInit={onInit}
      Router={Router}
    >
      <ThemeProvider>
        <Justlease />
      </ThemeProvider>
    </Listo>
  </>
);

export default setComponentMapping(componentMapping)<Props>(Project);
